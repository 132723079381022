import { OrderDirection, Selectors } from 'generated/content'
import { useQuery } from 'react-query'
import { useUserContent } from './useContember'

const QUERY = Selectors.query({
	listHousingUnitTemplate: [
		{ orderBy: [{ name: OrderDirection.asc }] },
		{
			id: true,
			name: true,
		},
	],
})

export function useHousingUnitTemplates() {
	const content = useUserContent()

	return useQuery('housingUnitTemplates', async () => {
		return (await content.query(QUERY)).listHousingUnitTemplate
	})
}
