import { useQuery } from 'react-query'
import { OrderDirection } from '../../generated/content'
import { useUserContent } from './useContember'

export function useDeveloperStats() {
	const contember = useUserContent()

	return useQuery(
		['developerStats'],
		async () => {
			return contember.query({
				listProject: [
					{ orderBy: [{ name: OrderDirection.asc }] },
					{
						id: true,
						name: true,

						buildings: [
							{ orderBy: [{ name: OrderDirection.asc }] },
							{
								id: true,
								name: true,
								housingUnits: [
									{ orderBy: [{ name: OrderDirection.asc }] },
									{
										id: true,
										name: true,
										waitingForConfigurator: true,
										configuratorBoilerPlates: [{}, { id: true }],
										selectedConfiguratorBoilerplate: [{}, { id: true }],
										__alias: {
											hasAtLestOneApprovedFinalization: {
												rootFolder: [
													{
														filter: {
															documents: {
																isFinalization: {
																	eq: true,
																},
																revisions: {
																	approved: { eq: true },
																},
															},
														},
													},
													{
														id: true,
													},
												],
											},
										},
										metadata: [
											{},
											{
												allDocumentCount: true,
												allApprovedCount: true,
												allUndecidedCount: true,
												allDeniedCount: true,
												documentCount: true,
												approvedCount: true,
												undecidedCount: true,
												deniedCount: true,
												finalizationCount: true,
												finalizationApprovedCount: true,
												finalizationUndecidedCount: true,
												finalizationDeniedCount: true,
												documentRevisionStandardTypeIsStandardCount: true,
												documentRevisionStandardTypeIsClientChangeCount:
													true,
												standardType: true,
											},
										],
									},
								],
							},
						],
					},
				],
			})
		},
		{
			refetchOnWindowFocus: false,
		}
	)
}
