import { Card, Nav, Tab } from 'components/Bootstrap'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { HousingUnitList } from 'components/HousingUnitList'
import { HousingUnitTemplateList } from 'components/HousingUnitTemplateList'
import { Icon } from 'components/Icon'
import { RouteLink } from 'components/NavLink'
import { PageTitle } from 'components/PageTitle'
import { ProjectList } from 'components/ProjectList'
import { useDeveloperStats } from 'hooks/useDeveloperStats'
import { useHousingUnitTemplates } from 'hooks/useHousingUnitTemplates'
import { useMyHousingUnits } from 'hooks/useMyHousingUnits'
import { useProjects } from 'hooks/useProjects'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { ROUTES } from 'src/constants'
import {
	useIsOverridableAdmin,
	useIsOverridableContractor,
	useIsOverridableDeveloper,
	useIsOverridablePrivileged,
	useIsOverridableSalesperson,
} from 'src/contexts/authContext'
import Layout from 'src/layouts/Layout.module.sass'

const icons = {
	success: <Icon name="approved" className="text-success" />,
	waiting: <Icon name="waitingApproved" className="text-light" />,
	danger: <Icon name="disapproved" className="text-danger" />,
}

export const actions = () => (
	<Nav as="ul">
		<Nav.Item as="li">
			<RouteLink route={ROUTES.ADD_PROJECT}>
				<a className="nav-link">Přidat projekt</a>
			</RouteLink>
		</Nav.Item>
		<Nav.Item as="li">
			<RouteLink route={ROUTES.USERS}>
				<a className="nav-link">Uživatelé</a>
			</RouteLink>
		</Nav.Item>
		<Nav.Item as="li">
			<RouteLink route={ROUTES.USERS_ACL}>
				<a className="nav-link">Přístup uživatelů</a>
			</RouteLink>
		</Nav.Item>
		<Nav.Item as="li">
			<RouteLink route={ROUTES.ADD_HOUSING_UNIT_TEMPLATE}>
				<a className="nav-link">Přidat šablonu</a>
			</RouteLink>
		</Nav.Item>
		<Nav.Item as="li">
			<RouteLink route={ROUTES.LOGS}>
				<a className="nav-link">Historie</a>
			</RouteLink>
		</Nav.Item>
		<Nav.Item as="li">
			<RouteLink route={ROUTES.SETTINGS}>
				<a className="nav-link">
					<Icon name="settings" /> Nastavení
				</a>
			</RouteLink>
		</Nav.Item>
	</Nav>
)

function AdminView() {
	const allProjects = useProjects()
	const allTemplates = useHousingUnitTemplates()
	const myHousingUnits = useMyHousingUnits()

	return (
		<div>
			<Breadcrumbs actions={actions()} />
			<div className={Layout.Body}>
				<Tab.Container defaultActiveKey="projects" id="tabs">
					<Card>
						<Card.Header>
							<Nav variant="tabs">
								<Nav.Item>
									<Nav.Link eventKey="projects">Projekty</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="housingUnitTemplates">Šablony</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="housingUnits">Byty</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="stats">Přehled</Nav.Link>
								</Nav.Item>
							</Nav>
						</Card.Header>
						<Tab.Content>
							<Tab.Pane eventKey="projects" title="Projekty">
								<ProjectList items={allProjects?.data ?? []} />
							</Tab.Pane>
							<Tab.Pane eventKey="housingUnitTemplates" title="Šablony">
								<HousingUnitTemplateList items={allTemplates?.data ?? []} />
							</Tab.Pane>
							<Tab.Pane eventKey="housingUnits" title="Byty">
								<HousingUnitList items={myHousingUnits?.data ?? []} />
							</Tab.Pane>
							<Tab.Pane eventKey="stats" title="Přehled">
								<DeveloperView />
							</Tab.Pane>
						</Tab.Content>
					</Card>
				</Tab.Container>
			</div>
		</div>
	)
}

function ContractorView() {
	const allProjects = useProjects()
	const myHousingUnits = useMyHousingUnits()

	return (
		<div>
			<Breadcrumbs />
			<div className={Layout.Body}>
				<Tab.Container defaultActiveKey="projects" id="tabs">
					<Card>
						<Card.Header>
							<Nav variant="tabs">
								<Nav.Item>
									<Nav.Link eventKey="projects">Projekty</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="housingUnits">Byty</Nav.Link>
								</Nav.Item>
							</Nav>
						</Card.Header>
						<Tab.Content>
							<Tab.Pane eventKey="projects" title="Projekty">
								<ProjectList items={allProjects?.data ?? []} />
							</Tab.Pane>
							<Tab.Pane eventKey="housingUnits" title="Byty">
								<HousingUnitList items={myHousingUnits?.data ?? []} />
							</Tab.Pane>
						</Tab.Content>
					</Card>
				</Tab.Container>
			</div>
		</div>
	)
}

function HousingOwnerView() {
	const router = useRouter()
	const allProjects = useProjects()
	const myHousingUnits = useMyHousingUnits()

	const housingUnitsCount = myHousingUnits?.data?.length ?? 0
	const projectsCount = allProjects?.data?.length ?? 0

	const firstId = myHousingUnits?.data?.[0]?.id ?? null

	React.useEffect(() => {
		if (housingUnitsCount === 1 && firstId) {
			const route = ROUTES.HOUSING_UNIT(firstId)
			router.push(route.href, route.as)
		}
	}, [housingUnitsCount, firstId, router])

	return (
		<div>
			<Breadcrumbs />
			<div className={Layout.Body}>
				<PageTitle>Moje byty</PageTitle>
				<HousingUnitList items={myHousingUnits.data ?? []} />
				{projectsCount > 1 && (
					<>
						<PageTitle>Projekty</PageTitle>
						<ProjectList items={allProjects.data ?? []} />
					</>
				)}
			</div>
		</div>
	)
}

export default function Home() {
	const isAdmin = useIsOverridableAdmin()
	const isContractor = useIsOverridableContractor()
	const isSalesperson = useIsOverridableSalesperson()
	const isDeveloper = useIsOverridableDeveloper()
	const isPrivileged = useIsOverridablePrivileged() && !isAdmin

	if (isDeveloper || isSalesperson || isContractor) {
		return <DeveloperView />
	}

	return isAdmin ? <AdminView /> : isPrivileged ? <ContractorView /> : <HousingOwnerView />
}

function DeveloperView() {
	const stats = useDeveloperStats()

	return (
		<div className={Layout.Body}>
			{stats.data && (
				<>
					{stats.data.listProject.map((p) => (
						<div key={p.id}>
							<div style={{ padding: 20 }}>
								<h3>{p.name}</h3>
							</div>

							{p.buildings.map((b) => {
								return (
									<div key={b.id}>
										<div style={{ padding: 20 }}>
											<h3>{b.name}</h3>
										</div>
										<table className="table table-hover">
											<thead className="thead thead-fade">
												<tr>
													<th scope="col" className="text-nowrap"></th>
													<th scope="col" className="text-nowrap">
														Byt
													</th>
													<th scope="col" className="text-nowrap">
														Konfigurátor
													</th>
													<th scope="col" className="text-nowrap">
														Čeká na zpracování
													</th>
													<th scope="col" className="text-nowrap">
														Čeká na klienta
													</th>
													<th scope="col" className="text-nowrap">
														Schváleno klientem
													</th>
													<th scope="col" className="text-nowrap">
														Finalizace
													</th>
												</tr>
											</thead>
											<tbody>
												{b.housingUnits.map((h) => {
													const hasConfigurator =
														h.configuratorBoilerPlates.length > 0

													const hasCompletedConfigurator =
														!h.waitingForConfigurator &&
														h.selectedConfiguratorBoilerplate

													const finalizationStatus = h.metadata
														?.finalizationApprovedCount
														? true
														: h.metadata?.finalizationDeniedCount
														? false
														: null

													const hasGeneratedFinalization =
														!!h.metadata?.finalizationCount

													const hasAtLeastOneApprovedFinalization =
														!!h.hasAtLestOneApprovedFinalization
															.rootFolder?.id

													return (
														<tr key={h.id}>
															<td className="td-minify"></td>
															<th scope="row">
																<Link href={`/housingUnit/${h.id}`}>
																	<a>{h.name}</a>
																</Link>
															</th>
															<td className="td-minify">
																{hasConfigurator ? (
																	hasCompletedConfigurator ? (
																		icons.success
																	) : (
																		icons.waiting
																	)
																) : (
																	<em className="text-light">
																		nemá
																	</em>
																)}
															</td>
															<td className="td-minify">
																{h.metadata?.deniedCount}
															</td>
															<td className="td-minify">
																{h.metadata?.undecidedCount}
															</td>
															<td className="td-minify text-success">
																{h.metadata?.approvedCount} /{' '}
																{h.metadata?.documentCount}
															</td>
															<td className="td-minify">
																{hasGeneratedFinalization ? (
																	<span
																		style={{
																			display: 'inline-block',
																			position: 'relative',
																		}}>
																		{!finalizationStatus &&
																			hasAtLeastOneApprovedFinalization && (
																				<span
																					style={{
																						display:
																							'inline-block',
																						position:
																							'absolute',
																						top: '-.4em',
																						left: '-.65em',
																						transform:
																							'scale(0.66)',
																						opacity: 0.75,
																					}}>
																					{icons.success}
																				</span>
																			)}
																		{finalizationStatus === true
																			? icons.success
																			: finalizationStatus ===
																			  false
																			? icons.danger
																			: icons.waiting}
																	</span>
																) : (
																	<em className="text-light">
																		nemá
																	</em>
																)}
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								)
							})}
						</div>
					))}
				</>
			)}
		</div>
	)
}
