import { useDeleteEntity } from 'hooks/useDeleteEntity'
import React from 'react'
import { EntityTypename, ROUTES } from 'src/constants'
import { useIsOverridableAdmin } from 'src/contexts/authContext'
import { areYouSure } from 'utils/areYouSure'
import { EntityLink } from './EntityLink'
import { IconButton, IconLink } from './IconButton'

export const ProjectList = React.memo(function ProjectList({
	items,
}: {
	items: {
		id: string
		name: string
	}[]
}) {
	const [del] = useDeleteEntity('deleteProject', ['useProjects'])
	const isAdmin = useIsOverridableAdmin()
	return (
		<table className="table table-hover">
			<tbody>
				{items.map((item) => (
					<tr key={item.id}>
						<td className="td-minify"></td>
						<th scope="row">
							<EntityLink type={EntityTypename.PROJECT} id={item.id}>
								{item.name}
							</EntityLink>
						</th>
						{isAdmin && (
							<td className="text-right">
								<IconLink name="settings" route={ROUTES.PROJECT(item.id, 'edit')} />
								<IconButton
									name="delete"
									onClick={() => areYouSure().then(() => del(item.id))}
								/>
							</td>
						)}
					</tr>
				))}
			</tbody>
		</table>
	)
})
