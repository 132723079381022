import { OrderDirection } from 'generated/content'
import { useQuery } from 'react-query'
import { useUserContent } from './useContember'

export function useMyHousingUnits() {
	const content = useUserContent()

	return useQuery('myHousingUnits', async () => {
		return (
			await content.query({
				listHousingUnit: [
					{
						orderBy: [{ name: OrderDirection.asc }],
						filter: { _isTemplateHousing: { eq: false } },
					},
					{
						id: true,
						name: true,
						building: [
							{},
							{
								id: true,
								name: true,
								project: [
									{},
									{
										id: true,
										name: true,
									},
								],
							},
						],
					},
				],
			})
		).listHousingUnit
	})
}
