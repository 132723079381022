import { OrderDirection, Selectors } from 'generated/content'
import { useQuery } from 'react-query'
import { useUserContent } from '../hooks/useContember'

const QUERY = Selectors.query({
	listProject: [{ orderBy: [{ name: OrderDirection.asc }] }, { id: true, name: true }],
})

export function useProjects() {
	const content = useUserContent()

	return useQuery('useProjects', async () => {
		return (await content.query(QUERY)).listProject
	})
}
