import { useDeleteEntity } from 'hooks/useDeleteEntity'
import React from 'react'
import { ROUTES } from 'src/constants'
import { areYouSure } from 'utils/areYouSure'
import { IconButton, IconLink } from './IconButton'
import { RouteLink } from './NavLink'

export const HousingUnitTemplateList = React.memo(function HousingUnitTemplateList({
	items,
}: {
	items?: {
		id: string
		name?: string
	}[]
}) {
	const [del] = useDeleteEntity('deleteHousingUnitTemplate', ['housingUnitTemplates'])

	return (
		<table className="table table-hover">
			<tbody>
				{items &&
					items.map((item) => (
						<tr key={item.id}>
							<td className="td-minify"></td>
							<th scope="row">
								<RouteLink route={ROUTES.HOUSING_UNIT_TEMPLATE(item.id)}>
									<a>{item.name}</a>
								</RouteLink>
							</th>
							<td className="text-right">
								<IconLink
									name="settings"
									route={ROUTES.HOUSING_UNIT_TEMPLATE(item.id, 'edit')}
								/>
								<IconButton
									name="delete"
									onClick={() => areYouSure().then(() => del(item.id))}
								/>
							</td>
						</tr>
					))}
			</tbody>
		</table>
	)
})
